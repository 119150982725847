import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Typography, Box, TableSortLabel, TablePagination, Avatar, Switch, Link
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { SessionContext } from './SessionContext';
import { useTheme } from '@mui/material/styles';

const permissionLevels = {
  0: 'Everyone',
  1: 'Subscriber',
  2: 'VIP',
  3: 'Moderator',
  4: 'Broadcaster',
};

const CommandsPublic = () => {
  const theme = useTheme();
  const { channelName } = useParams();
  const { session } = useContext(SessionContext);
  const [commands, setCommands] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('command');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCommands, setTotalCommands] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchCommands();
    fetchTotalCommands();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/api/twitch/user/${channelName}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('User not found');
    }
  };

  const fetchCommands = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/commands`, {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setCommands(response.data.data);
    } catch (error) {
      console.error('Error fetching commands:', error);
      setError('Error fetching commands');
    }
  };

  const fetchTotalCommands = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/commands/count`);
      setTotalCommands(response.data.data[0].total_count);
    } catch (error) {
      console.error('Error fetching total commands count:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderResponse = (response) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return response.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <Link href={part} target="_blank" rel="noopener noreferrer" key={index} sx={{ color: theme.palette.primary.main }}>
          {part}
        </Link>
      ) : (
        part
      )
    );
  };

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box padding={2}>
      {userInfo && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80, marginRight: 2 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {userInfo.display_name}'s Commands
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'command' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'command'}
                  direction={orderBy === 'command' ? order : 'asc'}
                  onClick={() => handleRequestSort('command')}
                >
                  Command
                </TableSortLabel>
              </TableCell>
              <TableCell>Response</TableCell>
              <TableCell>Aliases</TableCell>
              <TableCell>Permission Level</TableCell>
              <TableCell sortDirection={orderBy === 'is_active' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'is_active'}
                  direction={orderBy === 'is_active' ? order : 'asc'}
                  onClick={() => handleRequestSort('is_active')}
                >
                  Active
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.length > 0 ? (
              commands.map((command) => (
                <TableRow key={command.id}>
                  <TableCell>{command.command}</TableCell>
                  <TableCell sx={{ maxWidth: '300px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>{renderResponse(command.response)}</TableCell>
                  <TableCell sx={{ maxWidth: '200px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                    {command.global_aliases
                      ? `${command.global_aliases}${command.aliases ? `,${command.aliases}` : ''}`
                      : command.aliases || ''}
                  </TableCell>
                  <TableCell>{permissionLevels[command.permission_level]}</TableCell>
                  <TableCell>
                    <Switch
                      checked={command.is_active}
                      sx={{
                        pointerEvents: 'none',
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1">No commands available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCommands}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default CommandsPublic;
