import React, { useEffect, useRef, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Menu from './Menu';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useAuth } from './AuthContext';
import { SessionContext } from './SessionContext';
import twitchLogo from '../glitch_flat_white.png';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 129px)', // To account for header (85px + 3px) and footer (40px + 1px)
});

const MainContainer = styled('div')({
  display: 'flex',
  flex: 1,
});

const Sidebar = styled('div')(({ theme, isCollapsed }) => ({
  width: isCollapsed ? 0 : '200px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  flexShrink: 0,
  boxSizing: 'border-box',
  zIndex: 2,
  position: 'fixed',
  top: theme.spacing(11), // 88px
  bottom: theme.spacing(5), // 40px
  overflowY: 'auto',
  transition: 'width 0.3s',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const SidebarBottomContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
}));

const ContentWrapper = styled('div')(({ theme, isCollapsed }) => ({
  flex: 1,
  marginLeft: isCollapsed ? '16px' : '216px', // 16px or 216px, depending on sidebar state
  transition: 'margin-left 0.3s',
  overflow: 'hidden',
  padding: '8px',
}));

const Content = styled('div')(({ theme, showScrollbar }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  overflowY: showScrollbar ? 'auto' : 'hidden',
}));

const ToggleButton = styled('button')(({ theme, isCollapsed }) => ({
  position: 'fixed',
  top: '50%',
  left: isCollapsed ? 0 : '200px',
  zIndex: 3,
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacing(0.5),
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  transition: 'left 0.3s, border-radius 0.3s',
  fontSize: '0.8rem',
}));

const LoginButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff7e04',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#cc6503',
  },
  margin: theme.spacing(1),
  width: 'calc(100% - 32px)',
  maxWidth: '180px',
  alignSelf: 'center',
  fontSize: '0.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff7e04',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#cc6503',
  },
  margin: theme.spacing(1),
  width: 'calc(100% - 32px)',
  maxWidth: '180px',
  alignSelf: 'center',
  fontSize: '0.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { authState, logout } = useAuth();
  const { session, loginWallet, logoutWallet } = useContext(SessionContext);
  
  // Check if the current path is an admin path or special access path
  const isAdminPath = location.pathname.startsWith('/admin');
  const isSpecialAccessPath = location.pathname === '/special-access';
  // If current path is admin or special access, still show streamer menu
  const isStreamerDashboard = location.pathname.startsWith('/streamer-dashboard') || isAdminPath || isSpecialAccessPath;
  
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const contentRef = useRef();

  // Base menu items
  const baseStreamerMenuItems = [
    { label: 'Streamer Dashboard', link: '/streamer-dashboard' },
    { label: 'Dig Pool', link: '/streamer-dashboard/nft-pool' },
    { label: 'NFT Transactions', link: '/streamer-dashboard/nft-transactions' },
    { label: 'Token Pools', link: '/streamer-dashboard/token-pools' },
    { label: 'Token Transactions', link: '/streamer-dashboard/token-transactions' },
    { label: 'Commands', link: '/streamer-dashboard/commands' },
    // { label: 'Timed Messages', link: '/streamer-dashboard/timed-messages' },
    { label: 'Leaderboards', link: '/streamer-dashboard/leaderboard' },
    { label: 'Settings', link: '/streamer-dashboard/settings' },
  ];

  const baseViewerMenuItems = [
    { label: 'Viewer Dashboard', link: '/viewer-dashboard' },
    { label: 'Balances', link: '/viewer-dashboard/balances' },
    { label: 'Transactions', link: '/viewer-dashboard/transactions' },
  ];

  // Add protected routes based on authentication state and Twitch ID
  const getMenuItems = () => {
    let items = isStreamerDashboard ? [...baseStreamerMenuItems] : [...baseViewerMenuItems];
    
    // Add admin dashboard link for specific Twitch IDs
    if (authState.isAuthenticated && 
        authState.role === 'streamer' && 
        ['56731780', '123456789'].includes(authState.twitch_id)) {
      items.push({ label: 'Admin Dashboard', link: '/admin/dashboard' });
    }
    
    // Add special access link for specific Twitch ID
    if (authState.isAuthenticated && authState.twitch_id === '56731780') {
      items.push({ label: 'Special Access', link: '/special-access' });
    }
    
    return items;
  };
  
  const menuItems = getMenuItems();

  const toggleSidebar = () => {
    setIsCollapsed(prev => !prev);
  };

  useEffect(() => {
    const updateMinHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const viewportHeight = window.innerHeight;
        const newMinHeight = contentHeight > viewportHeight - theme.spacing(21.25) // 170px
          ? `${contentHeight}px`
          : 'calc(100vh - 128px)';
        setShowScrollbar(contentHeight > viewportHeight - theme.spacing(21.25));
      }
    };

    updateMinHeight();
    window.addEventListener('resize', updateMinHeight);
    const observer = new MutationObserver(updateMinHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener('resize', updateMinHeight);
      if (contentRef.current) {
        observer.disconnect();
      }
    };
  }, [children, theme]);

  return (
    <DashboardLayoutContainer>
      <MainContainer>
        <Sidebar theme={theme} isCollapsed={isCollapsed}>
          <Menu items={menuItems} />
          <SidebarBottomContainer>
            {session ? (
              <LoginButtonStyled onClick={logoutWallet}>
                <AccountBalanceWalletIcon style={{ color: 'white', marginRight: '8px' }} />
                <span style={{ flex: 1, textAlign: 'center' }}>Logout</span>
              </LoginButtonStyled>
            ) : (
              <LoginButtonStyled onClick={loginWallet}>
                <AccountBalanceWalletIcon style={{ color: 'white', marginRight: '8px' }} />
                <span style={{ flex: 1, textAlign: 'center' }}>Connect</span>
              </LoginButtonStyled>
            )}
            {authState.isAuthenticated && (
              <LogoutButton onClick={logout}>
                <img src={twitchLogo} alt="Twitch Logo" style={{ width: '20px', marginRight: '8px' }} />
                <span style={{ flex: 1, textAlign: 'center' }}>Logout</span>
              </LogoutButton>
            )}
          </SidebarBottomContainer>
        </Sidebar>
        <ContentWrapper theme={theme} isCollapsed={isCollapsed}>
          <ToggleButton theme={theme} onClick={toggleSidebar} isCollapsed={isCollapsed}>
            {isCollapsed ? <ArrowForwardIos /> : <ArrowBackIos />}
          </ToggleButton>
          <Content ref={contentRef} showScrollbar={showScrollbar} theme={theme}>
            {children}
          </Content>
        </ContentWrapper>
      </MainContainer>
    </DashboardLayoutContainer>
  );
};

export default DashboardLayout;
