import React, { useState, useEffect } from 'react';
import DigOverlay from './DigOverlay2';
import ChatOverlay from './ChatOverlay';
import EventOverlay from './EventOverlay';
import { useTheme } from '@mui/material/styles';
import { TextField, Box, MenuItem, Select, FormControl, InputLabel, IconButton, Container, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';

const OverlayPreview = () => {
  const [uuid, setUUID] = useState('');
  const [overlayUrl, setOverlayUrl] = useState('');
  const [type, setType] = useState('chat');
  const theme = useTheme();

  useEffect(() => {
    const generateUUID = async () => {
      try {
        const response = await axios.post('/api/overlays/generate', { type }, { withCredentials: true });
        if (response.data.success) {
          const newUUID = response.data.uuid;
          setUUID(newUUID);
          setOverlayUrl(`${window.location.origin}/overlays/${type === 'dig' ? 'dig-overlay2' : type}-overlay?uuid=${newUUID}`);
        } else {
          throw new Error('Failed to generate UUID');
        }
      } catch (error) {
        console.error('Error generating UUID:', error);
      }
    };

    generateUUID();
  }, [type]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(overlayUrl);
  };

  const renderOverlay = () => {
    switch (type) {
      case 'chat':
        return <ChatOverlay />;
      case 'dig':
        return <DigOverlay />;
      case 'event':
        return <EventOverlay />;
      default:
        return null;
    }
  };

  return (
    <Container
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        boxSizing: 'border-box',
        padding: '8px 16px',
      }}
    >
      <Grid container spacing={2} sx={{ mb: 2, width: '100%' }}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Type"
            >
              <MenuItem value="chat">Chat</MenuItem>
              <MenuItem value="dig">Dig</MenuItem>
              <MenuItem value="event">Event</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Overlay URL"
            value={overlayUrl}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton onClick={handleCopyToClipboard}>
            <ContentCopyIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box
        sx={{
          flex: 1,
          width: '80%',
          height: '80%',
          maxWidth: '80vw',
          maxHeight: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          border: '1px solid #ccc',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              transform: `scale(${Math.min(1, 0.8)})`,
              transformOrigin: 'center',
            }}
          >
            {renderOverlay()}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default OverlayPreview;
