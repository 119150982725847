import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Typography, Box, Button, Table, TableHead, TableBody, Paper, TablePagination, TableSortLabel, CircularProgress, TableContainer, TableCell, TableRow, Avatar } from '@mui/material';

const StreamerTransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('timestamp');
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTotalTransactions = async () => {
      try {
        const response = await axios.get('/api/token-transactions/count', {
          withCredentials: true,
        });

        setTotalTransactions(response.data.total_count);
      } catch (error) {
        console.error('Error fetching total transaction count:', error);
      }
    };

    fetchTotalTransactions();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/token-transactions', {
          withCredentials: true,
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: orderBy,
            sort_order: order,
          },
        });

        if (response.data && response.data.data && Array.isArray(response.data.data)) {
          setTransactions(response.data.data);
        } else {
          setTransactions([]);
          console.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [page, rowsPerPage, orderBy, order]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToCSV = async () => {
    try {
      const response = await axios.get('/api/token-transactions/count', {
        withCredentials: true,
      });
      const totalTransactions = response.data.total_count;

      let allTransactions = [];
      const maxResultsPerPage = 1000;
      const totalPages = Math.ceil(totalTransactions / maxResultsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/token-transactions', {
          withCredentials: true,
          params: {
            limit: maxResultsPerPage,
            offset: page * maxResultsPerPage,
            sort_by: orderBy,
            sort_order: order,
          },
        });
        allTransactions = allTransactions.concat(response.data.data);
      }

      const headers = ['Type', 'Username', 'Wallet', 'Amount', 'Symbol', 'Contract', 'Transaction ID', 'Timestamp'];
      const rows = allTransactions.map(transaction => [
        transaction.type,
        transaction.username,
        transaction.wallet,
        (transaction.amount / Math.pow(10, transaction.decimals)).toFixed(transaction.decimals),
        transaction.symbol,
        transaction.contract,
        `https://waxblock.io/transaction/${transaction.trx_id}`,
        transaction.timestamp,
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.map(cell => `"${cell}"`).join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `token_transactions_${Math.floor(Date.now() / 1000)}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getTokenIconUrl = (symbol, contract) => {
    return `https://raw.githubusercontent.com/alcorexchange/alcor-ui/master/assets/tokens/wax/${symbol.toLowerCase()}_${contract}.png`;
  };

  const getPlaceholderIcon = (symbol) => {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF8C33'];
    const color = colors[Math.floor(Math.random() * colors.length)];
    return (
      <Avatar sx={{ bgcolor: color, width: 24, height: 24, marginRight: '8px', fontSize: 12 }}>
        {symbol.slice(0, 2).toUpperCase()}
      </Avatar>
    );
  };

  const formatAmount = (amount, decimals) => {
    return parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" color="#FFFFFF" gutterBottom>
          Token Transaction History
        </Typography>
        <Button
            variant="contained"
            onClick={exportToCSV}
            disabled={transactions.length === 0}
          >
            <FileDownloadOutlinedIcon />
            &nbsp;Export
        </Button>
      </Box>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        View your token transaction history.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'type' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={() => handleRequestSort('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'username' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'username'}
                  direction={orderBy === 'username' ? order : 'asc'}
                  onClick={() => handleRequestSort('username')}
                >
                  Username
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'wallet' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'wallet'}
                  direction={orderBy === 'wallet' ? order : 'asc'}
                  onClick={() => handleRequestSort('wallet')}
                >
                  Wallet
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'amount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={() => handleRequestSort('amount')}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'trx_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'trx_id'}
                  direction={orderBy === 'trx_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('trx_id')}
                >
                  Transaction ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'timestamp' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'timestamp'}
                  direction={orderBy === 'timestamp' ? order : 'desc'}
                  onClick={() => handleRequestSort('timestamp')}
                >
                  Timestamp
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            ) : transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No transactions found.
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell>{transaction.type}</TableCell>
                  <TableCell>{transaction.username}</TableCell>
                  <TableCell>{transaction.wallet}</TableCell>
                  <TableCell>{formatAmount(transaction.amount, transaction.decimals)}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <img
                        src={getTokenIconUrl(transaction.symbol, transaction.contract)}
                        alt={transaction.symbol}
                        onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; e.target.nextSibling.style.display = 'block'; }}
                        style={{ width: 24, height: 24, marginRight: '8px' }}
                      />
                      <Box display="none">
                        {getPlaceholderIcon(transaction.symbol)}
                      </Box>
                      <Typography variant="body2" color="textPrimary" component="span">
                        {transaction.symbol}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{transaction.contract}</TableCell>
                  <TableCell>
                    <a
                      href={`https://waxblock.io/transaction/${transaction.trx_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                      {transaction.trx_id.slice(-12)}
                    </a>
                  </TableCell>
                  <TableCell>{formatDate(transaction.timestamp)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalTransactions}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[100, 250, 500]}
      />
    </Box>
  );
};

export default StreamerTransactionHistory;
