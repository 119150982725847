import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../components/AuthContext';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import WebSocketManager from '../utils/websocket';

const DigOverlay = () => {
  const { authState } = useAuth(); // Access the auth object
  const [ws, setWs] = useState(null);
  const [digMessage, setDigMessage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [queue, setQueue] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const theme = useTheme();
  const timerRef = useRef(null);
  const [uuid, setUUID] = useState(null);

  // Authenticate using UUID from URL or generate a new one
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let existingUUID = urlParams.get('uuid');

    const authenticateUUID = async (uuid) => {
      try {
        const response = await axios.get('/api/overlays/auth', {
          params: { uuid },
          withCredentials: true,
        });
        if (response.data.success) {
          setUUID(uuid);
          setErrorMessage(null); // Clear error message on successful authentication
        } else {
          throw new Error('Invalid or expired UUID');
        }
      } catch (error) {
        console.error('Error authenticating UUID:', error);
        setErrorMessage('Invalid or expired UUID. Redirecting to main overlay page...');
        setTimeout(() => {
          window.location.href = '/overlays/dig-overlay';
        }, 3000);
      }
    };

    const generateUUID = async () => {
      try {
        const response = await axios.post('/api/overlays/generate', { type: 'dig' }, { withCredentials: true });
        if (response.data.success) {
          const newUUID = response.data.uuid;
          authenticateUUID(newUUID);
        } else {
          throw new Error('Failed to generate UUID');
        }
      } catch (error) {
        console.error('Error generating UUID:', error);
        setErrorMessage('Error generating UUID. Please reload the overlay in your browser to authenticate.');
      }
    };

    if (existingUUID) {
      authenticateUUID(existingUUID);
    } else {
      generateUUID();
    }
  }, []);

  // WebSocket connection and message handling
  useEffect(() => {
    if (!uuid) return;

    const handleWebSocketMessage = (parsedMessage) => {
      if (parsedMessage.type === 'auth' && parsedMessage.success) {
        setErrorMessage(null); // Clear error message on successful authentication
      }

      if (parsedMessage.type === 'dig') {
        setQueue((prevQueue) => [...prevQueue, parsedMessage]);
      }
    };

    const handleWebSocketError = (errorMessage) => {
      setErrorMessage('WebSocket error: ' + errorMessage + '. Please reload the overlay in your browser to authenticate.');
    };

    const handleWebSocketClose = (reason) => {
      setErrorMessage(`Connection closed: ${reason}. Please reload the overlay in your browser to authenticate.`);
    };

    const wsManager = new WebSocketManager(
      'wss://ws.scurvy.dasker.co.uk:50443',
      uuid,
      handleWebSocketMessage,
      handleWebSocketError,
      handleWebSocketClose
    );

    return () => {
      wsManager.close();
    };
  }, [uuid]);

  useEffect(() => {
    if (queue.length > 0 && !showMessage) {
      const nextMessage = queue[0];
      setQueue((prevQueue) => prevQueue.slice(1));
      setImageLoaded(false);
      setShowMessage(false);
      setDigMessage(nextMessage);
    }
  }, [queue, showMessage]);

  const getImageUrl = (image) => {
    if (image.startsWith('http')) {
      return `https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(image)}&width=600&static=true`;
    } else {
      return `https://resizer.neftyblocks.com/?ipfs=${image}&width=600&static=true`;
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setShowMessage(true);
    const displayTime = Math.max(2000, 3000 - (queue.length * 500));
    timerRef.current = setTimeout(() => setShowMessage(false), displayTime);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const renderDigMessage = (message) => {
    const { username, assetName, collectionName, imageUrl, nsfw } = message;
    const imageSrc = getImageUrl(imageUrl);
    const textStyle = {
      color: '#FFFFFF',
      fontSize: '5vh',
      textShadow: '4px 4px 8px rgba(0, 0, 0, 0.7)',
      width: '100%',
    };
    const usernameStyle = {
      color: theme.palette.primary.main,
    };
    const assetNameStyle = {
      color: theme.palette.primary.main,
    };
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{ textAlign: 'center', width: '100%', height: '100%', boxSizing: 'border-box' }}>
        <Grid item style={{ marginBottom: '2vh', width: '100%' }}>
          <strong style={textStyle}>
            Congratulations <span style={usernameStyle}>{username}</span>!
          </strong>
        </Grid>
        <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={imageSrc} alt={assetName} onLoad={handleImageLoad} style={{ width: 'auto', height: '100%', maxHeight: '40vh', objectFit: 'contain', filter: nsfw ? 'blur(35px) brightness(0.75)' : 'none' }} />
        </Grid>
        <Grid item style={{ marginTop: '2vh', width: '100%' }}>
          <strong style={textStyle}>
            You dug up <span style={assetNameStyle}>{assetName}</span> from <span style={assetNameStyle}>{collectionName}</span>!
          </strong>
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.overlay,
        boxSizing: 'border-box',
      }}
    >
      {errorMessage ? (
        <div style={{ color: 'red', fontSize: '2rem', textAlign: 'center' }}>
          {errorMessage}
        </div>
      ) : (
        <Grow
          in={showMessage}
          timeout={{ enter: 1000, exit: 1000 }}
        >
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '100vww', maxHeight: '100vh' }}>
            {digMessage && renderDigMessage(digMessage)}
          </div>
        </Grow>
      )}
    </div>
  );
};

export default DigOverlay;
