class WebSocketManager {
  constructor(url, uuid, onMessage, onError, onClose) {
    this.url = url;
    this.uuid = uuid;
    this.onMessage = onMessage;
    this.onError = onError;
    this.onClose = onClose;
    this.socket = null;
    this.reconnectInterval = 5000; // 5 seconds
    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      const credentials = { type: 'uuid', uuid: this.uuid };
      this.socket.send(JSON.stringify(credentials)); // Send the UUID for authentication
    };

    this.socket.onmessage = (event) => {
      let rawMessage = event.data;
      rawMessage = rawMessage.replace(/[\u0000-\u001F\u007F]/g, ' ').trim();
      const parsedMessage = JSON.parse(rawMessage);
      this.onMessage(parsedMessage);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error.message);
      this.onError(error.message);
    };

    this.socket.onclose = (event) => {
      console.log(`Connection closed: ${event.reason}`);
      this.onClose(event.reason);
      setTimeout(() => this.connect(), this.reconnectInterval); // Attempt to reconnect
    };
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default WebSocketManager;
