import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper, Typography, Grid, Button, Card, CardMedia, CardContent, CardActions, 
  FormControl, InputLabel, Select, MenuItem, Avatar, Chip, Tooltip } from '@mui/material';
import { ShoppingCart, FilterList, AccountBalanceWallet } from '@mui/icons-material';
import axios from 'axios';

const StoreFront = () => {
  const { channelName } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [userBalance, setUserBalance] = useState(null);

  // Fetch user balance
  useEffect(() => {
    const fetchUserBalance = async () => {
      try {
        const response = await axios.get(`/api/viewer/balance/${channelName}`, { withCredentials: true });
        if (response.data.success && response.data.data.length > 0) {
          setUserBalance(response.data.data[0]);
        }
      } catch (err) {
        console.error('Error fetching user balance:', err);
        setError('Failed to fetch your balance. Please try again later.');
      }
    };

    if (channelName) {
      fetchUserBalance();
    }
  }, [channelName]);

  useEffect(() => {
    // Simulate fetching products
    setProducts([
      {
        id: 1,
        title: 'Scurvy T-Shirt',
        image: '/images/store/scurvy-tshirt.jpg',
        description: 'Premium cotton t-shirt featuring the Scurvy logo',
        price: 500,
        category: 'clothing'
      },
      {
        id: 2,
        title: 'WAX NFT Pack',
        image: '/images/store/wax-nft-pack.jpg',
        description: 'Limited edition NFT pack with exclusive digital collectibles',
        price: 1000,
        category: 'digital'
      },
      {
        id: 3,
        title: 'Scurvy Hoodie',
        image: '/images/store/scurvy-hoodie.jpg',
        description: 'Comfortable hoodie with embroidered Scurvy logo',
        price: 750,
        category: 'clothing'
      },
    ]);
  }, []);

  // Apply filtering whenever products or filter changes
  useEffect(() => {
    if (categoryFilter === 'all') {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter(product => product.category === categoryFilter));
    }
  }, [products, categoryFilter]);

  const handleBuyNow = (productId) => {
    setLoading(true);
    
    // Simulate purchase processing
    setTimeout(() => {
      setLoading(false);
      console.log(`Processing purchase for product ID: ${productId}`);
      // Here you would implement actual purchase logic
    }, 1000);
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  return (
    <Box sx={{ padding: 2, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: '8px 0', marginBottom: 1, color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <ShoppingCart sx={{ fontSize: 42, color: '#ff7e04' }} />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {channelName}'s Store
            </Typography>
            <Typography variant="body2">
              Redeem your loyalty tokens for rewards
            </Typography>
          </Grid>
        </Grid>
        
        {userBalance && (
          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#333652', borderRadius: 2, padding: '4px 12px', boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}>
            <AccountBalanceWallet sx={{ color: '#ff7e04', mr: 1, fontSize: '1.2rem' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#ff7e04', mr: 1 }}>
                  {Number(parseFloat(userBalance.balance)).toLocaleString()}
                </Typography>
                <Tooltip title={userBalance.display_name} arrow>
                  <Avatar 
                    src={userBalance.profile_image_url} 
                    sx={{ width: 18, height: 18, ml: 0.5 }}
                    alt={userBalance.token_name} 
                  />
                </Tooltip>
              </Box>
              <Typography variant="caption" sx={{ color: '#ccc', fontSize: '0.6rem' }}>
                {userBalance.token_name} Balance
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {error && (
        <Paper sx={{ padding: 1, mb: 1, backgroundColor: '#f44336', color: '#fff' }}>
          <Typography variant="body2">{error}</Typography>
        </Paper>
      )}
      
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FilterList sx={{ color: '#ff7e04', mr: 1, fontSize: '1.2rem' }} />
          <Typography variant="body2" sx={{ mr: 1 }}>Filter Products:</Typography>
          <FormControl size="small" sx={{ minWidth: 120, backgroundColor: '#333652', borderRadius: 1 }}>
            <InputLabel id="category-filter-label" sx={{ color: '#fff', fontSize: '0.8rem' }}>Category</InputLabel>
            <Select
              labelId="category-filter-label"
              id="category-filter"
              value={categoryFilter}
              label="Category"
              onChange={handleCategoryChange}
              sx={{ color: '#fff', fontSize: '0.8rem', height: '32px' }}
            >
              <MenuItem value="all">All Items</MenuItem>
              <MenuItem value="clothing">Clothing</MenuItem>
              <MenuItem value="digital">Digital Items</MenuItem>
            </Select>
          </FormControl>
        </Box>
        
        <Typography variant="caption" sx={{ color: '#ccc' }}>
          {filteredProducts.length} item{filteredProducts.length !== 1 ? 's' : ''} available
        </Typography>
      </Box>

      <Grid container spacing={1}>
        {filteredProducts.map((product) => (
          <Grid item xs={4} sm={4} md={3} lg={2} key={product.id}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              backgroundColor: '#424867', 
              color: '#fff',
              position: 'relative',
              borderRadius: '6px',
            }}>
              {/* Image Container - Square */}
              <Box sx={{ 
                position: 'relative', 
                width: '100%', 
                paddingBottom: '100%', /* Perfect square aspect ratio */
                overflow: 'hidden'
              }}>
                <CardMedia
                  component="img"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  image={product.image}
                  alt={product.title}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/MissingImage.jpg';
                  }}
                />
                {/* Category chip */}
                <Box sx={{ position: 'absolute', top: 4, right: 4, zIndex: 1 }}>
                  <Chip 
                    label={product.category} 
                    size="small"
                    sx={{ 
                      backgroundColor: product.category === 'clothing' ? '#3f51b5' : '#8bc34a',
                      color: '#fff',
                      fontWeight: 'bold',
                      height: '20px',
                      '& .MuiChip-label': {
                        fontSize: '0.65rem',
                        padding: '0 6px',
                      }
                    }} 
                  />
                </Box>
                {/* Price tag overlay on image */}
                <Box sx={{ 
                  position: 'absolute', 
                  bottom: 0, 
                  left: 0,
                  backgroundColor: 'rgba(0,0,0,0.7)', 
                  padding: '2px 6px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Typography variant="subtitle2" sx={{ color: '#ff7e04', fontWeight: 'bold', mr: 0.5 }}>
                    {product.price.toLocaleString()}
                  </Typography>
                  {userBalance && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="caption" sx={{ color: '#fff', mr: 0.5 }}>
                        {userBalance.token_name}
                      </Typography>
                      <Avatar 
                        src={userBalance.profile_image_url} 
                        sx={{ width: 12, height: 12 }}
                        alt={userBalance.token_name} 
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              
              <CardContent sx={{ flexGrow: 1, py: 0.5, px: 1, pb: '0px !important', height: '45px' }}>
                <Typography 
                  variant="subtitle2"
                  component="h2" 
                  sx={{ 
                    mb: 0.2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '0.8rem',
                    fontWeight: 'bold'
                  }}
                >
                  {product.title}
                </Typography>
                <Typography 
                  variant="caption"
                  sx={{ 
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: 1.1,
                    height: '2.2em',
                    fontSize: '0.65rem'
                  }}
                >
                  {product.description}
                </Typography>
              </CardContent>
              
              <CardActions sx={{ p: 0.5, mt: 'auto' }}>
                <Button 
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{
                    margin: '0',
                    minWidth: '100%',
                    height: '24px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: userBalance && parseFloat(userBalance.balance) < product.price ? '0.55rem' : '0.65rem',
                  }}
                  disabled={loading || (userBalance && parseFloat(userBalance.balance) < product.price)}
                  onClick={() => handleBuyNow(product.id)}
                >
                  {userBalance && parseFloat(userBalance.balance) < product.price ? 
                    'Insufficient Balance' : 'Buy Now'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {filteredProducts.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
          <Typography variant="body2" sx={{ color: '#ccc' }}>
            No items found in this category
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StoreFront;