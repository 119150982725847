import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Typography, Box, TableSortLabel, MenuItem, IconButton, TextField, Select, Switch, Button, FormControl, InputLabel, Link
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Modal from './Modal';
import { useTheme } from '@mui/material/styles';

const permissionLevels = {
  Everyone: 0,
  Subscriber: 1,
  VIP: 2,
  Moderator: 3,
  Broadcaster: 4,
};

// Set axios default to include credentials
axios.defaults.withCredentials = true;

const Commands = () => {
  const theme = useTheme();
  const [commands, setCommands] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [commandToDelete, setCommandToDelete] = useState(null);
  const [editCommandValues, setEditCommandValues] = useState({
    id: null,
    command: '',
    response: '',
    aliases: '',
    is_active: '',
    permission_level: ''
  });
  const [newCommandValues, setNewCommandValues] = useState({
    command: '',
    response: '',
    aliases: '',
    is_active: true,
    permission_level: permissionLevels.Everyone
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCommands();
  }, []);

  const fetchCommands = async () => {
    try {
      const response = await axios.get('/api/commands');
      setCommands(response.data.data);
    } catch (error) {
      console.error('Error fetching commands:', error);
    }
  };

  const openModal = (command) => {
    setEditCommandValues({
      id: command.id,
      command: command.command.startsWith('!') ? command.command.slice(1) : command.command,
      response: command.response,
      aliases: command.aliases ? command.aliases.replace(/!/g, '') : '',
      is_active: command.is_active,
      permission_level: command.permission_level
    });
    setIsModalOpen(true);
  };

  const openDeleteModal = (command) => {
    setCommandToDelete(command);
    setIsDeleteModalOpen(true);
  };

  const openAddModal = () => {
    setNewCommandValues({
      command: '',
      response: '',
      aliases: '',
      is_active: true,
      permission_level: permissionLevels.Everyone
    });
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsAddModalOpen(false);
    setErrorMessage('');
    setIsProcessing(false);
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
  };

  const handleCommandChange = (field, isEdit = true) => (event) => {
    let value = event.target.value;

    // Remove any '!' characters from the command and aliases fields
    if (field === 'command' || field === 'aliases') {
      value = value.replace(/!/g, '');
    }

    if (isEdit) {
      setEditCommandValues({
        ...editCommandValues,
        [field]: value
      });
    } else {
      setNewCommandValues({
        ...newCommandValues,
        [field]: value
      });
    }
  };

  const handleSave = async () => {
    try {
      setIsProcessing(true);
      const formattedAliases = formatAliases(editCommandValues.aliases);
      await axios.post('/api/commands/update', {
        id: editCommandValues.id,
        command: `!${editCommandValues.command}`, // Prefix the command with !
        response: editCommandValues.response,
        aliases: formattedAliases,
        is_active: editCommandValues.is_active,
        permission_level: editCommandValues.permission_level
      });
      setCommands((prevCommands) =>
        prevCommands.map((cmd) =>
          cmd.id === editCommandValues.id
            ? { ...cmd, ...editCommandValues, command: `!${editCommandValues.command}` } // Save with !
            : cmd
        )
      );
      fetchCommands();
      setIsSuccess(true);
      setMessage('Command updated successfully!');
    } catch (error) {
      console.error('Error updating command:', error);
      setIsError(true);
      setMessage('Error updating command');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAdd = async () => {
    try {
      setIsProcessing(true);
      await axios.post('/api/commands/add', {
        command: `!${newCommandValues.command}`, // Prefix the command with !
        response: newCommandValues.response,
        aliases: formatAliases(newCommandValues.aliases),
        is_active: newCommandValues.is_active,
        permission_level: newCommandValues.permission_level
      });
      fetchCommands();
      setIsSuccess(true);
      setMessage('Command added successfully!');
    } catch (error) {
      console.error('Error adding command:', error);
      setIsError(true);
      setMessage('Error adding command');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDelete = async () => {
    if (!commandToDelete) return;

    try {
      setIsProcessing(true);
      await axios.post('/api/commands/delete', { id: commandToDelete.id });
      setCommands((prevCommands) => prevCommands.filter((cmd) => cmd.id !== commandToDelete.id));
      setIsSuccess(true);
      setMessage('Command deleted successfully!');
    } catch (error) {
      console.error('Error deleting command:', error);
      setIsError(true);
      setMessage('Error deleting command');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleActiveChange = async (command) => {
    try {
      const updatedCommand = { ...command, is_active: !command.is_active };
      await axios.post('/api/commands/update', {
        id: command.id,
        command: command.command,
        response: command.response,
        aliases: command.aliases,
        is_active: updatedCommand.is_active,
        permission_level: command.permission_level,
      });
      setCommands((prevCommands) =>
        prevCommands.map((cmd) =>
          cmd.id === command.id ? updatedCommand : cmd
        )
      );
    } catch (error) {
      console.error('Error updating active status:', error);
    }
  };

  const getPermissionLevelKey = (value) => {
    return Object.keys(permissionLevels).find(key => permissionLevels[key] === value);
  };

  const formatAliases = (aliases) => {
    const trimmedAliases = aliases.trim();
    if (!trimmedAliases) {
      return null;
    }

    // Split the aliases, trim each one, and prefix with '!'
    return trimmedAliases
      .split(',')
      .map((alias) => `!${alias.trim()}`) // Ensure single '!' prefix
      .join(',');
  };

  const renderResponse = (response) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return response.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <Link href={part} target="_blank" rel="noopener noreferrer" key={index} sx={{ color: theme.palette.primary.main }}>
          {part}
        </Link>
      ) : (
        part
      )
    );
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Box>
          <Typography variant="h5">
            Commands
          </Typography>
          <Typography variant="body1" color="#A0A0A0">
            Manage your commands here. You can edit the command name, response, aliases, permission level, or toggle the active status.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={openAddModal}
          sx={{ color: '#FFFFFF', textTransform: 'none', width: 'auto', whiteSpace: 'nowrap' }}
        >
          ADD COMMAND
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection="asc" sx={{ maxWidth: '150px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                <TableSortLabel active direction="asc">Command</TableSortLabel>
              </TableCell>
              <TableCell sx={{ maxWidth: '300px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>Response</TableCell>
              <TableCell sx={{ maxWidth: '200px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>Aliases</TableCell>
              <TableCell>Permission Level</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.map((command) => (
              <TableRow key={command.id}>
                <TableCell sx={{ maxWidth: '150px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>{command.command}</TableCell>
                <TableCell sx={{ maxWidth: '300px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>{renderResponse(command.response)}</TableCell>
                <TableCell sx={{ maxWidth: '200px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                  {command.global_aliases
                    ? `${command.global_aliases}${command.aliases ? `,${command.aliases}` : ''}`
                    : command.aliases || ''}
                </TableCell>
                <TableCell>{getPermissionLevelKey(command.permission_level)}</TableCell>
                <TableCell>
                  <Switch
                    checked={command.is_active}
                    onChange={() => handleActiveChange(command)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => openModal(command)}>
                    <EditIcon sx={{ color: 'secondary.main' }} />
                  </IconButton>
                  {command.command_type === 'custom' && (
                    <IconButton onClick={() => openDeleteModal(command)}>
                      <DeleteIcon sx={{ color: 'error.main' }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding a New Command */}
      <Modal
        open={isAddModalOpen}
        onClose={closeModal}
        title="Add Command"
        content={
          <Box>
            {/* Command input with exclamation mark adornment */}
            <TextField
              label="Command"
              value={newCommandValues.command}
              onChange={(e) => {
                if (e.target.value.length <= 24) handleCommandChange('command', false)(e);
              }}
              fullWidth
              margin="normal"
              helperText={errorMessage}
              error={Boolean(errorMessage)}
              inputProps={{ maxLength: 24 }}
            />
            <TextField
              label="Response"
              value={newCommandValues.response}
              onChange={handleCommandChange('response', false)}
              fullWidth
              margin="normal"
              multiline
              minRows={6}
              maxRows={10}
              inputProps={{ maxLength: 500 }}
              helperText={
                <Typography variant="body2" color={newCommandValues.response.length === 500 ? '#ff0000' : newCommandValues.response.length > 450 ? '#ff7e04' : '#ffffff'}>
                  {`${newCommandValues.response.length}/500 characters`}
                </Typography>
              }
            />
            <TextField
              label="Aliases (comma separated)"
              value={newCommandValues.aliases}
              onChange={(e) => {
                handleCommandChange('aliases', false)(e);
              }}
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 200 }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel shrink>Permission Level</InputLabel>
              <Select
                label="Permission Level"
                value={newCommandValues.permission_level}
                onChange={handleCommandChange('permission_level', false)}
                fullWidth
              >
                {Object.entries(permissionLevels).map(([key, value]) => (
                  <MenuItem key={value} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        onSubmit={handleAdd}
        isProcessing={isProcessing}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
      />

      {/* Modal for Editing */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        title={editCommandValues.id < 0 ? `Editing Command !${editCommandValues.command}` : "Editing Command"}
        content={
          <Box>
            {editCommandValues.id > 0 && (
              <FormControl fullWidth margin="normal" variant="outlined">
                <TextField
                  label="Command"
                  value={editCommandValues.command}
                  onChange={handleCommandChange('command')}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 24 }}
                  variant="outlined"
                />
              </FormControl>
            )}
            {editCommandValues.id > 0 && (
              <TextField
                label="Response"
                value={editCommandValues.response}
                onChange={handleCommandChange('response')}
                fullWidth
                multiline
                minRows={6}
                maxRows={10}
                inputProps={{ maxLength: 500 }}
                helperText={
                  <Typography
                    variant="body2"
                    color={
                      editCommandValues.response.length === 500
                        ? '#ff0000'
                        : editCommandValues.response.length > 450
                        ? '#ff7e04'
                        : '#ffffff'
                    }
                  >
                    {`${editCommandValues.response.length}/500 characters`}
                  </Typography>
                }
                margin="normal"
                variant="outlined"
              />
            )}
            <TextField
              label="Aliases (comma separated)"
              value={editCommandValues.aliases}
              onChange={handleCommandChange('aliases')}
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 200 }}
              helperText={
                <Typography
                  variant="body2"
                  color="#FFFFFF"
                >
                  {`"Aliases" are additional commands that trigger the same response. Separate aliases with commas.`}
                </Typography>
              }
              variant="outlined"
            />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel shrink>Permission Level</InputLabel>
              <Select
                label="Permission Level"
                value={editCommandValues.permission_level}
                onChange={handleCommandChange('permission_level')}
                fullWidth
                disabled={isProcessing}
                variant="outlined"
              >
                {Object.entries(permissionLevels).map(([key, value]) => (
                  <MenuItem key={value} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        onSubmit={handleSave}
        isProcessing={isProcessing}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
      />

      {/* Modal for Delete Confirmation */}
      <Modal
        open={isDeleteModalOpen}
        onClose={closeModal}
        title="Confirm Delete"
        content={
          <Box>
            <Typography variant="body1">
              Are you sure you want to delete the command "{commandToDelete?.command}"?
            </Typography>
          </Box>
        }
        onSubmit={handleDelete}
        isProcessing={isProcessing}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
      />
    </Box>
  );
};

export default Commands;
