import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, TableBody, TableHead, Paper, Typography, Box, IconButton, Button, Checkbox, TableContainer, TableCell, TableRow, TablePagination, TableSortLabel, CircularProgress, Tabs, Tab
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios';

// Define leaderboard types and their configurations
const leaderboardTypes = [
  {
    id: 'balances',
    label: 'Balances',
    endpoint: '/api/leaderboard/balance',
    defaultSortBy: 'balance',
    defaultSortOrder: 'desc',
    columns: [
      { id: 'username', label: 'Username', sortable: true, dataType: 'text' },
      { id: 'wallet', label: 'Wallet', sortable: true, dataType: 'text' },
      { id: 'balance', label: 'Balance', sortable: true, dataType: 'float' },
      { id: 'created_at', label: 'Created At', sortable: true, dataType: 'date' },
      { id: 'updated_at', label: 'Updated At', sortable: true, dataType: 'date' },
    ]
  },
  {
    id: 'dig',
    label: 'Dig Stats',
    endpoint: '/api/leaderboard/dig',
    defaultSortBy: 'total_digs',
    defaultSortOrder: 'desc',
    columns: [
      { id: 'username', label: 'Username', sortable: true, dataType: 'text' },
      { id: 'wallet', label: 'Wallet', sortable: true, dataType: 'text' },
      { id: 'dig_success', label: 'Dig Successes', sortable: true, dataType: 'integer' },
      { id: 'total_digs', label: 'Total Digs', sortable: true, dataType: 'integer' },
      { id: 'dig_perc', label: 'Dig Percentage', sortable: true, dataType: 'percentage' },
      { id: 'total_dig_cost', label: 'Total Dig Cost', sortable: true, dataType: 'integer' },
    ]
  },
];

// TabPanel component for the tab content
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`leaderboard-tabpanel-${index}`}
      aria-labelledby={`leaderboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [activeTab, setActiveTab] = useState(0);
  const [sortBy, setSortBy] = useState(leaderboardTypes[0].defaultSortBy);
  const [sortOrder, setSortOrder] = useState(leaderboardTypes[0].defaultSortOrder);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  // Initialize tab and sort settings based on URL hash
  const initializeFromHash = useCallback(() => {
    const hash = window.location.hash.substr(1).toLowerCase(); 
    
    if (hash) {
      const tabIndex = leaderboardTypes.findIndex(
        (type) => type.id.toLowerCase() === hash
      );
      
      if (tabIndex >= 0) {
        const tabConfig = leaderboardTypes[tabIndex];
        setActiveTab(tabIndex);
        setSortBy(tabConfig.defaultSortBy);
        setSortOrder(tabConfig.defaultSortOrder);
        setPage(0);
        console.log(`Initialized tab ${tabIndex} (${tabConfig.id}) with sort by ${tabConfig.defaultSortBy} ${tabConfig.defaultSortOrder}`);
      } else {
        window.history.replaceState(null, null, `#${leaderboardTypes[0].id}`);
      }
    } else {
      window.history.replaceState(null, null, `#${leaderboardTypes[0].id}`);
    }
    
    setInitialized(true);
  }, []);

  useEffect(() => {
    initializeFromHash();
    
    const handleHashChange = () => {
      initializeFromHash();
    };
    
    window.addEventListener('hashchange', handleHashChange);
    
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [initializeFromHash]);

  const fetchTotalUsers = async () => {
    try {
      const leaderboardType = leaderboardTypes[activeTab];
      const countEndpoint = `${leaderboardType.endpoint}/count`;
      
      const response = await axios.get(countEndpoint, { withCredentials: true });
      setTotalUsers(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total users count:', error);
    }
  };

  useEffect(() => {
    if (initialized) {
      fetchTotalUsers();
    }
  }, [activeTab, initialized]);

  // Fetch leaderboard data with sorting and pagination
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const leaderboardType = leaderboardTypes[activeTab];
      const response = await axios.get(leaderboardType.endpoint, {
        withCredentials: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
          sort_order: sortOrder,
        },
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      fetchUsers();
    }
  }, [page, rowsPerPage, sortBy, sortOrder, activeTab, initialized]);

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
    
    const newTab = leaderboardTypes[newValue];
    setSortBy(newTab.defaultSortBy);
    setSortOrder(newTab.defaultSortOrder);
    
    const newHash = leaderboardTypes[newValue].id;
    window.history.replaceState(null, null, `#${newHash}`);
  };

  const formatCellValue = (value, dataType) => {
    if (value === null || value === undefined) return '';
    
    switch(dataType) {
      case 'percentage':
        return typeof value === 'number' ? 
          `${(value * 100).toFixed(2)}%` : 
          (typeof value === 'string' && !isNaN(parseFloat(value)) ? 
            `${(parseFloat(value) * 100).toFixed(2)}%` : value);
      
      case 'integer':
        return typeof value === 'number' ? 
          Math.round(value).toLocaleString() : 
          (typeof value === 'string' && !isNaN(parseInt(value)) ? 
            parseInt(value).toLocaleString() : value);
      
      case 'float':
        return typeof value === 'number' ? 
          value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 
          (typeof value === 'string' && !isNaN(parseFloat(value)) ? 
            parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value);
      
      case 'currency':
        return typeof value === 'number' ? 
          value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 
          (typeof value === 'string' && !isNaN(parseFloat(value)) ? 
            parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : value);
      
      case 'date':
        try {
          // Parse the UTC date string
          const date = new Date(value);
          
          // Check if the date is valid
          if (isNaN(date.getTime())) return value;
          
          // The date is already automatically adjusted to the local timezone
          // when formatted with toLocaleString()
          return date.toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short' // Add timezone name (e.g., "EST", "PST")
          });
        } catch (error) {
          console.error('Error formatting date:', error);
          return value;
        }
      
      default:
        return value;
    }
  };

  const exportToCSV = async () => {
    setExportLoading(true);
    try {
      let allUsers = [];
      const leaderboardType = leaderboardTypes[activeTab];
      const maxResultsPerPage = 1000;
      
      let exportTotalCount = totalUsers;
      try {
        const countResponse = await axios.get(`${leaderboardType.endpoint}/count`, { 
          withCredentials: true 
        });
        if (countResponse.data && countResponse.data.total_count) {
          exportTotalCount = countResponse.data.total_count;
        }
      } catch (error) {
        console.error('Error fetching accurate count for export:', error);
      }

      const totalPages = Math.ceil(exportTotalCount / maxResultsPerPage);
      console.log(`Exporting ${exportTotalCount} users in ${totalPages} pages`);
      
      for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        console.log(`Fetching export page ${pageNum + 1}/${totalPages}`);
        
        try {
          const response = await axios.get(leaderboardType.endpoint, {
            withCredentials: true,
            params: {
              limit: maxResultsPerPage,
              offset: pageNum * maxResultsPerPage,
              sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
              sort_order: sortOrder,
            },
          });
          
          if (Array.isArray(response.data)) {
            allUsers = allUsers.concat(response.data);
            console.log(`Retrieved ${response.data.length} users from page ${pageNum + 1}`);
          } else {
            console.error(`Invalid response format on page ${pageNum + 1}:`, response.data);
          }
        } catch (pageError) {
          console.error(`Error fetching page ${pageNum + 1}:`, pageError);
        }
      }

      console.log(`Total users collected for export: ${allUsers.length}`);
      
      if (allUsers.length === 0) {
        console.error('No data to export');
        setExportLoading(false);
        return;
      }

      const columns = leaderboardType.columns;
      const headers = columns.map(column => column.label);

      const rows = allUsers.map(user => 
        columns.map(column => {
          const columnId = column.id;
          if (columnId in user) {
            if (column.dataType === 'percentage') {
              return typeof user[columnId] === 'number' ? 
                (user[columnId] * 100).toFixed(2) : user[columnId];
            }
            // Format dates for CSV exports in a standardized format
            if (column.dataType === 'date') {
              try {
                const date = new Date(user[columnId]);
                if (!isNaN(date.getTime())) {
                  // For CSV export, use ISO format with timezone offset to make it clear
                  return date.toISOString(); // Keep full ISO format including timezone
                }
              } catch (e) {}
            }
            return user[columnId];
          }
          return '';
        })
      );

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${leaderboardType.id}_leaderboard_${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    } finally {
      setExportLoading(false);
    }
  };

  const renderTable = () => {
    const leaderboardType = leaderboardTypes[activeTab];
    const columns = leaderboardType.columns;

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.twitch_id || `row-${Math.random()}`}>
                  {columns.map((column) => {
                    const cellValue = user[column.id];
                    
                    return (
                      <TableCell key={`${user.twitch_id || Math.random()}-${column.id}`}>
                        {column.id === 'wallet' && cellValue ? (
                          <a
                            href={`https://atomichub.io/profile/wax-mainnet/${cellValue}?blockchain=wax-mainnet&order=desc&sort=transferred#inventory`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                          >
                            {cellValue}
                          </a>
                        ) : (
                          formatCellValue(cellValue, column.dataType)
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Typography variant="h5">
            Leaderboards
          </Typography>
          <Typography variant="body1" color="#A0A0A0">
            View your top loyalty token holders, spenders and chatters.
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={exportToCSV}
          disabled={users.length === 0 || exportLoading}
          startIcon={exportLoading ? <CircularProgress size={20} color="inherit" /> : <FileDownloadOutlinedIcon />}
        >
          {exportLoading ? 'Exporting...' : 'Export'}
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          aria-label="Leaderboard Tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { backgroundColor: '#fff' } }}
          sx={{
            minHeight: '48px',
            '& .MuiTabs-flexContainer': {
              display: 'flex',
            },
            '& .MuiTab-root': {
              minWidth: '120px',
              width: 'auto',
              padding: '12px 16px',
              fontSize: '0.875rem',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              color: '#fff',
            },
            '& .Mui-selected': {
              fontWeight: 'bold',
            },
            '& .MuiTabs-scrollButtons': {
              color: '#fff',
              '&.Mui-disabled': {
                opacity: 0.3,
              },
            }
          }}
        >
          {leaderboardTypes.map((type, index) => (
            <Tab key={type.id} label={type.label} />
          ))}
        </Tabs>
      </Box>

      {leaderboardTypes.map((type, index) => (
        <TabPanel key={type.id} value={activeTab} index={index}>
          {renderTable()}
          
          <TablePagination
            component="div"
            count={totalUsers}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50, 100, 250]}
            labelDisplayedRows={({ from, to, count }) => 
              `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default Leaderboard;