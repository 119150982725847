import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableHead, TableContainer, Paper, Typography, Box, TableSortLabel, TableCell, TableRow, TablePagination, Avatar, Switch, Tooltip, IconButton, TextField
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { SessionContext } from './SessionContext';
import { AccountContext } from './AccountContext';
import { Info as InfoIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import Modal from './Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const TokenPoolPublic = () => {
  const { channelName } = useParams();
  const { session } = useContext(SessionContext);
  const { account } = useContext(AccountContext);
  const [pools, setPools] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('symbol');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPools, setTotalPools] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const [redemptionDisplay, setRedemptionDisplay] = useState('');
  const [command, setCommand] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchUserInfo();
    fetchTotalPools();
    fetchPools();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/api/twitch/user/${channelName}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('User not found');
    }
  };

  const fetchPools = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/token-pools`, {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setPools(response.data.data);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  const fetchTotalPools = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/token-pools/count`);
      setTotalPools(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total pools count:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validationSchema = Yup.object().shape({
    redemptionAmount: Yup.number()
      .required('Redemption amount is required')
      .min(Yup.ref('min_redeem'), `Amount must be at least ${selectedPool?.min_redeem}`)
      .max(Yup.ref('max_redeem'), `Amount cannot exceed ${selectedPool?.max_redeem}`),
  });

  const formik = useFormik({
    initialValues: {
      redemptionAmount: '',
      min_redeem: selectedPool?.min_redeem || 0,
      max_redeem: selectedPool?.max_redeem || Infinity,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedPool) {
        updateRedemptionDisplay(values.redemptionAmount, selectedPool.rate, selectedPool.decimals, selectedPool.symbol, selectedPool.min_redeem, selectedPool.max_redeem, selectedPool.balance);
        setCommand(`!redeem ${values.redemptionAmount} ${selectedPool.symbol}`);
      }
    },
  });

  const handleOpenInfoModal = (pool) => {
    setSelectedPool(pool);
    setOpen(true);
    formik.resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPool(null);
    formik.resetForm();
    setRedemptionDisplay('');
    setCommand('');
  };

  const updateRedemptionDisplay = (amount, rate, decimals, symbol, minRedeem, maxRedeem, balance) => {
    if (amount && rate && decimals !== undefined && symbol) {
      if (amount < minRedeem) {
        setRedemptionDisplay(`Amount must be at least ${minRedeem}`);
        setCommand('');
      } else if (amount > maxRedeem && maxRedeem !== 0) {
        setRedemptionDisplay(`Amount cannot exceed ${maxRedeem}`);
        setCommand('');
      } else {
        let redemptionAmount = (amount * (rate / 100)).toFixed(decimals);
        redemptionAmount = parseFloat(redemptionAmount).toLocaleString(undefined, { minimumFractionDigits: decimals });
        if (parseFloat(redemptionAmount) > parseFloat(balance)) {
          setRedemptionDisplay(`The pool does not have enough balance to redeem that amount.`);
          setCommand('');
        } else {
          setRedemptionDisplay(
            `You will receive ${redemptionAmount} ${symbol} before any boosters are applied.`
          );
          setCommand(`!redeem ${amount} ${symbol}`);
        }
      }
    } else {
      setRedemptionDisplay('');
      setCommand('');
    }
  };

  const handleCopyCommand = () => {
    navigator.clipboard.writeText(command);
    setSnackbarOpen(true);
  };

  const handleRedemptionAmountChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('redemptionAmount', value);
    if (selectedPool) {
      updateRedemptionDisplay(value, selectedPool.rate, selectedPool.decimals, selectedPool.symbol, selectedPool.min_redeem, selectedPool.max_redeem, selectedPool.balance);
    }
  };

  const getTokenIconUrl = (symbol, contract) => {
    return `https://raw.githubusercontent.com/alcorexchange/alcor-ui/master/assets/tokens/wax/${symbol.toLowerCase()}_${contract}.png`;
  };

  const getPlaceholderIcon = (symbol) => {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF8C33'];
    const color = colors[Math.floor(Math.random() * colors.length)];
    return (
      <Avatar sx={{ bgcolor: color, width: 24, height: 24, marginRight: '8px', fontSize: 12 }}>
        {symbol.slice(0, 2).toUpperCase()}
      </Avatar>
    );
  };

  const getModalContent = () => {
    if (selectedPool) {
      return (
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ width: '100%', maxWidth: 400, minHeight: 200 }}>
          <TextField
            label="Redemption Amount"
            variant="outlined"
            fullWidth
            name="redemptionAmount"
            value={formik.values.redemptionAmount}
            onChange={handleRedemptionAmountChange}
            onBlur={formik.handleBlur}
            type="number"
            margin="normal"
            helperText={
              <Typography variant="body2" color="#FFFFFF" sx={{ minHeight: '1.5em' }}>
                {redemptionDisplay || ' '}
              </Typography>
            }
            FormHelperTextProps={{ sx: { color: 'white' } }}
          />
          {command && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography variant="body2" color="#FFFFFF">
                Command: {command}
              </Typography>
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleCopyCommand} sx={{ color: 'white', ml: 1 }}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      );
    }
    return null;
  };

  if (error && !open) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box padding={2}>
      {userInfo && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80, marginRight: 2 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {userInfo.display_name}'s Token Pools
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Token Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'rate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'rate'}
                  direction={orderBy === 'rate' ? order : 'asc'}
                  onClick={() => handleRequestSort('rate')}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'min_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'min_redeem'}
                  direction={orderBy === 'min_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('min_redeem')}
                >
                  Min Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'max_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'max_redeem'}
                  direction={orderBy === 'max_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('max_redeem')}
                >
                  Max Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'cooldown' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'cooldown'}
                  direction={orderBy === 'cooldown' ? order : 'asc'}
                  onClick={() => handleRequestSort('cooldown')}
                >
                  Cooldown
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'is_active' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'is_active'}
                  direction={orderBy === 'is_active' ? order : 'asc'}
                  onClick={() => handleRequestSort('is_active')}
                >
                  Active
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Redeem Example
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pools.length > 0 ? (
              pools.map((pool) => (
                <TableRow key={pool.token_id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <img
                        src={getTokenIconUrl(pool.symbol, pool.contract)}
                        alt={pool.symbol}
                        onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; e.target.nextSibling.style.display = 'block'; }}
                        style={{ width: 24, height: 24, marginRight: '8px' }}
                      />
                      <Box display="none">
                        {getPlaceholderIcon(pool.symbol)}
                      </Box>
                      <Typography variant="body2" color="textPrimary" component="span">
                        {pool.symbol}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{pool.contract}</TableCell>
                  <TableCell>{parseFloat(pool.balance).toLocaleString(undefined, { minimumFractionDigits: pool.decimals })}</TableCell>
                  <TableCell>{`${parseFloat(pool.rate).toFixed(2)}%`}</TableCell>
                  <TableCell>{pool.min_redeem}</TableCell>
                  <TableCell>{pool.max_redeem === 0 ? '∞' : pool.max_redeem}</TableCell>
                  <TableCell>{pool.cooldown}</TableCell>
                  <TableCell>
                    <Switch
                      checked={pool.is_active}
                      sx={{
                        pointerEvents: 'none',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Click to see your redemption example and the command">
                      <IconButton onClick={() => handleOpenInfoModal(pool)} sx={{ color: 'white' }}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Typography variant="body1">No token pools available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalPools}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={`Redemption Example for ${selectedPool?.symbol}`}
        content={getModalContent()}
        hideSubmit={true}
      />
    </Box>
  );
};

export default TokenPoolPublic;
