import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';
import { useTheme } from '@mui/material/styles';
import { Grow } from '@mui/material'; // Transition effect

const EventOverlay = () => {
  const { authState } = useAuth();
  const [ws, setWs] = useState(null);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const theme = useTheme();

  // Mapping of event types to images (all set to the same image for now)
  const eventImageMap = {
    'channel.follow': '/images/notification.png',
    'channel.subscribe': '/images/notification.png',
    'channel.cheer': '/images/notification.png',
    'channel.raid': '/images/notification.png',
    'default': '/images/notification.png',
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    const socket = new WebSocket('wss://ws.scurvy.dasker.co.uk:50443');

    socket.onopen = () => {
      const credentials = { type: 'auth', session_key: authState.session_key };
      socket.send(JSON.stringify(credentials)); // Send the session_key for authentication
    };

    socket.onmessage = (event) => {
      let rawMessage = event.data;
      rawMessage = rawMessage.replace(/[\u0000-\u001F\u007F-\u009F]/g, ' ').trim();
      const parsedMessage = JSON.parse(rawMessage);

      if (parsedMessage.type === 'event') {
        triggerNotification(parsedMessage);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error.message);
    };

    socket.onclose = (event) => {
      console.log(`Connection closed: ${event.reason}`);
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, [authState]);

  const triggerNotification = (event) => {
    setCurrentNotification(event);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 5000); // Show notification for 5 seconds
  };

  const renderEventNotification = () => {
    if (!currentNotification) return null;

    let message;
    const imageUrl = eventImageMap[currentNotification.subscription_type] || eventImageMap['default']; // Get image URL from map

    switch (currentNotification.subscription_type) {
      case 'channel.follow':
        message = `${currentNotification.user_name} followed ${currentNotification.broadcaster_user_name}`;
        break;
      case 'channel.subscribe':
        message = `${currentNotification.user_name} subscribed to ${currentNotification.broadcaster_user_name}`;
        break;
      case 'channel.cheer':
        message = `${currentNotification.user_name} cheered with ${currentNotification.bits} bits!`;
        break;
      case 'channel.raid':
        message = `${currentNotification.from_broadcaster_user_name} raided with ${currentNotification.viewers} viewers!`;
        break;
      default:
        message = `New event from ${currentNotification.broadcaster_user_name}`;
    }

    return (
      <Grow in={showNotification} timeout={500}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            borderRadius: '10px',
            padding: '2rem',
            width: '50vw',
            height: '50vh',
            position: 'absolute',
            top: '25%',
            left: '25%',
          }}
        >
          <img src={imageUrl} alt="notification-placeholder" style={{ width: '100px', height: '100px', marginBottom: '1rem' }} />
          <h2 style={{ fontSize: '2rem', marginTop: '1rem', textAlign: 'center' }}>{message}</h2>
        </div>
      </Grow>
    );
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.overlay,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {renderEventNotification()}
    </div>
  );
};

export default EventOverlay;
