import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Grid, Button, Alert } from '@mui/material';
import { AdminPanelSettings } from '@mui/icons-material';

const AdminDashboard = ({ authState }) => {
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // In a real implementation, you would fetch admin data here
    setAdminData({
      twitchId: authState.twitch_id,
      role: authState.role,
      accessLevel: 'Admin'
    });
  }, [authState]);

  const handleAdminAction = () => {
    setLoading(true);
    // Simulate admin action
    setTimeout(() => {
      setLoading(false);
      setAdminData(prev => ({
        ...prev,
        lastAction: new Date().toISOString()
      }));
    }, 1000);
  };

  if (!adminData) {
    return (
      <Box sx={{ padding: 4, textAlign: 'center' }}>
        <Typography variant="h5">Loading admin dashboard...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: '12px 0', marginBottom: 2, color: '#fff' }}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <AdminPanelSettings sx={{ fontSize: 64, color: '#ff7e04' }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  Admin Dashboard
                </Typography>
                <Typography variant="body1">
                  Restricted access area for authorized Twitch users only
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 3, backgroundColor: '#424867', color: '#fff' }}>
            <Typography variant="h6" gutterBottom>Admin Information</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography><strong>Twitch ID:</strong> {adminData.twitchId}</Typography>
              <Typography><strong>Role:</strong> {adminData.role}</Typography>
              <Typography><strong>Access Level:</strong> {adminData.accessLevel}</Typography>
              {adminData.lastAction && (
                <Typography><strong>Last Action:</strong> {new Date(adminData.lastAction).toLocaleString()}</Typography>
              )}
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 3, backgroundColor: '#424867', color: '#fff' }}>
            <Typography variant="h6" gutterBottom>Admin Actions</Typography>
            <Box sx={{ mt: 2 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleAdminAction}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Perform Admin Action'}
              </Button>
              
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ padding: 3, backgroundColor: '#424867', color: '#fff' }}>
            <Typography variant="h6" gutterBottom>Note</Typography>
            <Typography>
              This is a placeholder for the admin dashboard. In a real implementation, 
              this page would contain administrative tools and data only accessible 
              to specifically authorized Twitch users with IDs: 56731780 and 123456789.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;