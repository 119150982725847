import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, Paper, Typography, Box, IconButton, Button, Checkbox, TableContainer, TableCell, TableRow, TablePagination, TableSortLabel, TextField, CircularProgress, Grid
} from '@mui/material';
import Modal from './Modal';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const DigPool = ({ authState }) => {
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [result, setResult] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('date');
  const [totalAssets, setTotalAssets] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageTitle, setImageTitle] = useState('');

  const fetchTotalAssets = async () => {
    try {
      const response = await axios.get('/api/nft-assets/count', {
        withCredentials: true,
      });

      setTotalAssets(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total assets count:', error);
    }
  };

  const fetchAssets = async () => {
    try {
      const response = await axios.get('/api/nft-assets', {
        withCredentials: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort_by: sortBy,
          sort_order: sortOrder,
        },
      });

      setAssets(response.data);
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };

  useEffect(() => {
    fetchTotalAssets();
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [page, rowsPerPage, sortBy, sortOrder]);

  const refreshData = () => {
    fetchTotalAssets();
    fetchAssets();
    setSelectedAssets([]);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = assets.map((n) => n.asset_id);
      setSelectedAssets(newSelecteds);
      return;
    }
    setSelectedAssets([]);
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedAssets.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1));
    } else if (selectedIndex === selectedAssets.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1),
      );
    }

    setSelectedAssets(newSelected);
  };

  const handleWithdrawSelected = () => {
    if (selectedAssets.length === 0) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setResult(null);
    setLoadingMessage('');
    setWalletAddress('');
    setIsConfirmed(false);
    refreshData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const isSelected = (id) => selectedAssets.indexOf(id) !== -1;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const exportToCSV = async () => {
    try {
      if (!authState || !authState.twitch_id) {
        console.error('No twitch_id found in authState.');
        return;
      }

      const countResponse = await axios.get('/api/nft-assets/count', {
        withCredentials: true,
      });
      const totalAssets = countResponse.data.total_count;

      let allAssets = [];
      const maxResultsPerPage = 1000;
      const totalPages = Math.ceil(totalAssets / maxResultsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/nft-assets', {
          withCredentials: true,
          params: {
            limit: maxResultsPerPage,
            offset: page * maxResultsPerPage,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
        });
        allAssets = allAssets.concat(response.data);
      }

      const headers = ['Asset ID', 'Image', 'Collection', 'Collection Name', 'Schema Name', 'Template ID', 'Name', 'Date'];
      const rows = allAssets.map(asset => [
        asset.asset_id,
        asset.image,
        asset.collection_name,
        asset.collection,
        asset.schema_name,
        asset.template_id,
        asset.name.replace(/"/g, '""'),
        formatDate(asset.date),
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.map(cell => `"${cell}"`).join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `nft_assets_${Math.floor(Date.now() / 1000)}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  const handleDepositClick = () => {
    if (!authState || !authState.twitch_id) {
      console.error('No twitch_id found in authState.');
      return;
    }
    const memo = `deposit|${authState.twitch_id}`;
    const url = `https://wax.atomichub.io/trading/nft-transfer?partner=dig.sb&memo=${encodeURIComponent(memo)}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageClick = (asset) => {
    let imageUrl = '';
    if (asset.image) {
      if (asset.image.startsWith('http')) {
        imageUrl = `https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(asset.image)}&width=600&static=true`;
      } else {
        imageUrl = `https://resizer.neftyblocks.com/?ipfs=${asset.image}&width=600&static=true`;
      }
      setImageSrc(imageUrl);
      setImageTitle(asset.name);
      setImageModalOpen(true);
    }
  };
 
  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageSrc('');
    setImageTitle('');
  };
  
  const formik = useFormik({
    initialValues: {
      walletAddress: '',
    },
    validationSchema: Yup.object({
      walletAddress: Yup.string()
        .matches(/^[a-z1-5.]{1,12}$/, 'Invalid WAX wallet address.')
        .required('WAX wallet address is required.'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setLoadingMessage('Processing your withdrawal...');
      try {
        const response = await axios.post(
          '/api/transfer-nft',
          {
            to: values.walletAddress,
            asset_ids: selectedAssets,
            memo: `withdraw|${authState.twitch_id}`,
          },
          { withCredentials: true }
        );
    
        if (response.data.success) {
          setLoadingMessage('Waiting for transaction to be confirmed...');
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for an additional 2 seconds
          const trx_id = response.data.result[0].trx_id;
          const trx_url = `https://waxblock.io/transaction/${trx_id}`;
          setResult(`NFT transfer successful. Transaction ID: ${trx_id}`);
          refreshData();
        } else {
          setResult(`Error during NFT transfer: ${response.data.message}`);
        }
      } catch (error) {
        setResult(`Error during NFT transfer: ${error.message}`);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    setIsConfirmed(true);
    formik.handleSubmit();
  };

  return (
    <Box sx={{ padding: '16px' }}>
        <Typography variant="h5" color="#FFFFFF">
          NFT Dig Pool
        </Typography>
      <Typography variant="body1" color="#A0A0A0">
        Manage your digital assets here. Select assets to withdraw or click on an individual asset to view details.
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            variant="contained"
            onClick={handleDepositClick}
          >
            DEPOSIT
          </Button>
          <Button
            variant="contained"
            onClick={handleWithdrawSelected}
            disabled={selectedAssets.length === 0}
          >
            WITHDRAW
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={exportToCSV}
            disabled={assets.length === 0}
          >
            <FileDownloadOutlinedIcon />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selectedAssets.length > 0 && selectedAssets.length < assets.length}
                  checked={assets.length > 0 && selectedAssets.length === assets.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'asset_id'}
                  direction={sortBy === 'asset_id' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('asset_id')}
                >
                  Asset ID
                </TableSortLabel>
              </TableCell>
              <TableCell>Image</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'collection_name'}
                  direction={sortBy === 'collection_name' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('collection_name')}
                >
                  Collection
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'collection'}
                  direction={sortBy === 'collection' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('collection')}
                >
                  Collection Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'schema_name'}
                  direction={sortBy === 'schema_name' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('schema_name')}
                >
                  Schema
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'template_id'}
                  direction={sortBy === 'template_id' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('template_id')}
                >
                  Template ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortBy === 'name' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'date'}
                  direction={sortBy === 'date' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset) => {
              const isItemSelected = isSelected(asset.asset_id);
                return (
                <TableRow
                  key={asset.asset_id}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(event) => handleCheckboxClick(event, asset.asset_id)}
                    inputProps={{ 'data-id': asset.asset_id }}
                  />
                  </TableCell>
                  <TableCell>
                  <a
                    href={`https://atomichub.io/explorer/asset/wax-mainnet/${asset.asset_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                  >
                    {asset.asset_id}
                  </a>
                  </TableCell>
                  <TableCell>
                  {asset.image && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <img
                      src={`https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(asset.image)}&width=100&static=true`}
                      alt={asset.name}
                      style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => handleImageClick(asset)}
                      />
                    </Box>
                  )}
                  </TableCell>
                  <TableCell>
                  {asset.collection_name ? (
                    <a
                    href={`https://wax.atomichub.io/explorer/collection/wax-mainnet/${asset.collection_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                    {asset.collection_name}
                    </a>
                  ) : (
                    asset.collection_name
                  )}
                  </TableCell>
                  <TableCell>
                  {asset.collection_name ? (
                    <a
                    href={`https://wax.atomichub.io/explorer/collection/wax-mainnet/${asset.collection_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                    {asset.collection}
                    </a>
                  ) : (
                    asset.collection
                  )}
                  </TableCell>
                  <TableCell>
                  {asset.schema_name ? (
                    <a
                    href={`https://wax.atomichub.io/explorer/schema/wax-mainnet/${asset.collection_name}/${asset.schema_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                    {asset.schema_name}
                    </a>
                  ) : (
                    asset.schema_name
                  )}
                  </TableCell>
                  <TableCell>
                  {asset.template_id ? (
                    <a
                    href={`https://wax.atomichub.io/explorer/template/wax-mainnet/${asset.collection_name}/${asset.template_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                    {asset.template_id}
                    </a>
                  ) : (
                    asset.template_id
                  )}
                  </TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell>{formatDate(asset.date)}</TableCell>
                </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalAssets}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[100, 250, 500]}
      />
      <Modal
        open={imageModalOpen}
        onClose={handleImageModalClose}
        title={imageTitle} // Set the title
        content={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={imageSrc}
              alt={imageTitle}
              style={{ maxWidth: '80vw', maxHeight: '40vh', objectFit: 'contain' }}
            />
          </Box>
        }
        hideSubmit={true}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title="Confirm Withdrawal"
        content={
          <Box display="flex" flexDirection="column" alignItems="center">
            {loading ? (
              // Loading state with spinner and message
              <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress size={50} sx={{ color: '#ff7e04' }} />
                <Typography variant="h6" color="#FFFFFF" mt={2}>{loadingMessage}</Typography>
              </Box>
            ) : result ? (
        // Result state with success or failure icons
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          {result.includes('Transaction ID') ? (
            <>
              <CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />
              <Typography variant="h6" color="#FFFFFF" mt={2}>
                Withdrawal successful!
              </Typography>
              <Typography variant="body1" color="#FFFFFF" mt={1}>
                <a
                  href={`https://waxblock.io/transaction/${result.split('Transaction ID: ')[1].trim()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#ff7e04', textDecoration: 'underline' }}
                >
                  View the transaction
                </a>
              </Typography>
            </>
          ) : (
            <>
              <CancelIcon sx={{ fontSize: 50, color: 'red' }} />
              <Typography variant="h6" color="#FFFFFF" mt={2}>
                Withdrawal failed!
              </Typography>
              <Typography variant="body1" color="#FFFFFF" mt={1}>
                {result}
              </Typography>
            </>
          )}
        </Box>
      ) : (
        // Initial form with assets and confirm button
        <>
          <Typography variant="subtitle1" color="#FFFFFF">
            You are withdrawing {selectedAssets.length} assets
          </Typography>
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            sx={{ overflowX: 'auto', width: '100%', maxHeight: '150px', padding: '16px 0', marginBottom: '16px' }}  // Added padding and margin to image container
          >
                  {selectedAssets.map((id) => {
                    const asset = assets.find(a => a.asset_id === id);
                    return (
                      <Grid item key={id}>
                        <img
                          src={`https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(asset.image)}&width=100&static=true`}
                          alt={asset.name}
                          style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    label="WAX Wallet Address"
                    variant="outlined"
                    name="walletAddress"
                    value={formik.values.walletAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    required
                    error={formik.touched.walletAddress && Boolean(formik.errors.walletAddress)}
                    helperText={formik.touched.walletAddress && formik.errors.walletAddress}
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    sx={{ mt: 2 }}
                    onClick={handleConfirm}
                  >
                    Confirm
                  </Button>
                </form>
              </>
            )}
          </Box>
        }
        hideSubmit={true}
      />
    </Box>
  );
};

export default DigPool;
