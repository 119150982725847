import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, TableContainer, Paper, Typography, Box, TableSortLabel, TableCell, TableRow, TablePagination, Avatar, CircularProgress
} from '@mui/material';
import Modal from './Modal';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const DigPoolPublic = () => {
  const { channelName } = useParams();
  const [assets, setAssets] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('total_assets');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalAssets, setTotalAssets] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageTitle, setImageTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchUserInfo();
    fetchTotalAssets();
    fetchAssets();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/api/twitch/user/${channelName}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('User not found');
    }
  };

  const fetchAssets = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/dig-pool`, {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setAssets(response.data.data);
    } catch (error) {
      console.error('Error fetching assets:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalAssets = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/dig-pool/count`);
      setTotalAssets(response.data.data[0].total_count);
    } catch (error) {
      console.error('Error fetching total assets count:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageClick = (asset) => {
    let imageUrl = '';
    if (asset.image) {
      imageUrl = `https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(asset.image)}&width=600&static=true`;
      setImageSrc(imageUrl);
      setImageTitle(asset.name);
      setImageModalOpen(true);
    }
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageSrc('');
    setImageTitle('');
  };

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box padding={2}>
      {userInfo && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80, marginRight: 2 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {userInfo.display_name}'s Dig Pool
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell sortDirection={orderBy === 'collection_name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'collection_name'}
                  direction={orderBy === 'collection_name' ? order : 'asc'}
                  onClick={() => handleRequestSort('collection_name')}
                >
                  Collection
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'schema_name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'schema_name'}
                  direction={orderBy === 'schema_name' ? order : 'asc'}
                  onClick={() => handleRequestSort('schema_name')}
                >
                  Schema
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'template_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'template_id'}
                  direction={orderBy === 'template_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('template_id')}
                >
                  Template ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'total_assets' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'total_assets'}
                  direction={orderBy === 'total_assets' ? order : 'asc'}
                  onClick={() => handleRequestSort('total_assets')}
                >
                  Total Assets
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'percentage_of_pool' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'percentage_of_pool'}
                  direction={orderBy === 'percentage_of_pool' ? order : 'asc'}
                  onClick={() => handleRequestSort('percentage_of_pool')}
                >
                  Percentage Of Pool
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            ) : assets.length > 0 ? (
              assets.map((asset) => (
                <TableRow key={asset.asset_id}>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      {asset.image && (
                        <img
                          src={`https://resizer.neftyblocks.com/?ipfs=${encodeURIComponent(asset.image)}&width=100&static=true`}
                          alt={asset.name}
                          style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                          onClick={() => handleImageClick(asset)}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {asset.collection_name ? (
                      <a
                        href={`https://wax.atomichub.io/explorer/collection/wax-mainnet/${asset.collection_name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                      >
                        {asset.collection_name}
                      </a>
                    ) : (
                      asset.collection_name
                    )}
                  </TableCell>
                  <TableCell>
                    {asset.schema_name ? (
                      <a
                        href={`https://wax.atomichub.io/explorer/schema/wax-mainnet/${asset.collection_name}/${asset.schema_name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                      >
                        {asset.schema_name}
                      </a>
                    ) : (
                      asset.schema_name
                    )}
                  </TableCell>
                  <TableCell>
                    {asset.template_id ? (
                      <a
                        href={`https://wax.atomichub.io/explorer/template/wax-mainnet/${asset.collection_name}/${asset.template_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                      >
                        {asset.template_id}
                      </a>
                    ) : (
                      asset.template_id
                    )}
                  </TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell>{asset.total_assets.toLocaleString()}</TableCell>
                  <TableCell>{Number(asset.percentage_of_pool).toFixed(2)}%</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="body1">No assets available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          component="div"
          count={totalAssets} // Ensure totalAssets is used correctly
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[50, 100, 250]}
        />
      )}
      <Modal
        open={imageModalOpen}
        onClose={handleImageModalClose}
        title={imageTitle}
        content={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={imageSrc}
              alt={imageTitle}
              style={{ maxWidth: '80vw', maxHeight: '40vh', objectFit: 'contain' }}
            />
          </Box>
        }
        hideSubmit={true}
      />
    </Box>
  );
};

export default DigPoolPublic;
